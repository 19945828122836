<template>
  <div class="creation-step">
    <div v-if="!isEditing" class="step-heading">
      <h1 class="step-heading__title">Highlight your skills</h1>
      <p class="step-heading__text">
        Describe your abilities and competencies that help you deliver
        outstanding results.
      </p>
    </div>

    <draggable
      v-if="skills.length"
      v-model="skills"
      tag="div"
      class="experience"
      v-bind="dragOptions"
      :sort="false"
      :disabled="!!editingExperience"
      handle=".preview-controls__btn--move"
      @start="dragStart"
      @end="dragEnd"
    >
      <transition-group
        type="transition"
        tag="div"
        :name="isDrag ? 'flip-list' : null"
      >
        <div v-for="skill of skills" :key="skill.id" class="experience__item">
          <SkillForm
            :isEditing="isEditing"
            :experience="skill"
            :experienceList="skills"
            :disabled="isEditingExperience"
            :activeId="editingExperience"
            :isMobileDisplay="isMobileDisplay"
            @edit="openEditing"
            @cancel="cancelSkillEditing"
            @create="createSkill"
            @update="updateSkill"
            @remove="removeSkill"
          />
        </div>
      </transition-group>
    </draggable>

    <AddingBlock @addBlock="addNewData(editingExperience)">
      Add new skill
    </AddingBlock>

    <div v-if="!isEditing" class="creation-form-header">
      <button
        class="creation-form-header__preview"
        type="button"
        @click.prevent="openSidebar"
      >
        <AppIcon componentName="SearchIcon" />
      </button>
    </div>

    <FooterButton
      v-if="!isShowBackButton"
      :isEditing="isEditing"
      :ishideMobileFooter="hideMobileFooter"
      @goNextStep="goNextStep"
    ></FooterButton>

    <FooterButtonv2
      v-if="isShowBackButton"
      :isEditing="isEditing"
      :ishideMobileFooter="hideMobileFooter"
      @goNextStep="goNextStep"
    ></FooterButtonv2>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import draggable from "vuedraggable";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import "vue2-datepicker/index.css";

import { CREATING_RESUME_NAMES } from "@/helpers/rout-constants";
import HelperResumeHash from "@/helpers/resume-hash";
import HelperEditingHash from "@/helpers/editing-hash";
import AppToast from "@/helpers/toast-methods";

import { resumeCreationMixin } from "@/mixins/ResumeCreationStepsMixin";
import { experienceMixin } from "@/mixins/ExperienceMixin";
import SkillForm from "@/views/resume-creation/step-4/components/SkillForm";
import AddingBlock from "@/components/creation/AddingBlock";
import { setIntermediateResume } from "@/helpers/save-intermediate-data";
import {
  COMPONENT_NAMES,
  LOCAL_COMPONENTS_NAMES,
} from "@/helpers/components-names";
import { mixpanelMixin } from "@/mixins/MixPanelMixin";
import { eventBus } from "@/helpers/event-bus";
import CookieHelper from "@/helpers/cookie-helper";
import FooterButton from "@/components/creation/FooterButton";
import FooterButtonv2 from "@/components/creation/FooterButtonv2";

export default {
  name: COMPONENT_NAMES.RESUME_CREATION.SKILLS,
  components: {
    AddingBlock,
    SkillForm,
    FooterButton,
    FooterButtonv2,
    draggable,
  },
  mixins: [resumeCreationMixin, experienceMixin, mixpanelMixin],

  props: {
    isEditing: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      initialSkills: "resumeCreation/getSkills",
      defaultSkill: "resumeCreation/getDefaultSkill",
    }),
  },

  data() {
    return {
      skills: [],
      nextStepName: CREATING_RESUME_NAMES.STEP_5,
      prevStepName: CREATING_RESUME_NAMES.STEP_3,
      isShowBackButton: false,
    };
  },

  methods: {
    resumeHash() {
      return this.isEditing ? HelperEditingHash.get() : HelperResumeHash.get();
    },

    async addNewData(isEditingExperience) {
      if (isEditingExperience) {
        if (
          document.querySelector(".experience-form.experience-form--active")
        ) {
          const activeForm = document.querySelector(
            ".experience-form.experience-form--active"
          );
          activeForm
            .querySelector(".experience-form-btns__item--submit")
            .click();
        }

        await this.$store.dispatch("loader/pending");

        //if (this.checkValidation && this.checkValidation()) {
        setTimeout(() => {
          this.addExperience("skills", this.defaultSkill);
        }, 1000);
        //}
      } else {
        //await this.$store.dispatch("loader/pending");
        this.addExperience("skills", this.defaultSkill);
      }

      //setTimeout(() => {
      this.$store.dispatch("loader/done");
      //}, 2000);
    },

    async createSkill(skill) {
      this.editingExperience = null;

      const temporaryId = skill.id;

      delete skill.id;
      skill.resume_hash = this.resumeHash();

      await this.$store.dispatch("loader/pending");

      try {
        const newExperience = await this.$store.dispatch(
          "resumeCreation/createSkill",
          skill
        );

        await setIntermediateResume();

        const temporaryExperienceIndex = this.skills.findIndex(
          (localExperience) => {
            return localExperience.id === temporaryId;
          }
        );

        // this.$set(this.skills, temporaryExperienceIndex, newExperience);

        this.skills[temporaryExperienceIndex].id = newExperience.id;

        delete this.skills[temporaryExperienceIndex].temporary;
      } catch (e) {
        this.cancelSkillEditing();
        console.error(e);
      }

      this.mixpanelTrack("V2-ADDSKILL", {
        Skill: skill.name,
      });

      await this.$store.dispatch("loader/done");
    },

    async updateSkill(skill, resetSkills = true) {
      skill.resume_hash = this.resumeHash();

      await this.$store.dispatch("loader/pending");

      try {
        await this.$store.dispatch("resumeCreation/updateSkill", skill);

        if (resetSkills) {
          await this.$store.dispatch(
            "resumeCreation/getResume",
            this.resumeHash()
          );
          this.cancelSkillEditing();
          // AppToast.toastSuccess("Skill was successfully updated!");
        }
      } catch (e) {
        console.error(e);
      }
      await this.$store.dispatch("loader/done");
    },

    async removeSkill(skillId) {
      await this.$store.dispatch("loader/pending");

      try {
        await this.$store.dispatch("resumeCreation/removeSkill", {
          id: skillId,
          hash: this.resumeHash(),
        });
        await this.cancelSkillEditing();
        await this.resetIndexes(
          this.skills,
          (data) =>
            this.$store.dispatch("resumeCreation/updateSkillGroup", data),
          this.cancelSkillEditing
        );
        // await this.$store.dispatch("resumeCreation/getResume");
        // this.cancelSkillEditing();
      } catch (e) {
        console.error(e);
      }
      await this.$store.dispatch("loader/done");
    },

    cancelSkillEditing() {
      this.editingExperience = null;

      this.setInitialExperience(this.initialSkills, "skills");

      setIntermediateResume();
    },

    async dragEnd() {
      this.isDrag = false;

      await this.resetIndexes(
        this.skills,
        (data) => this.$store.dispatch("resumeCreation/updateSkillGroup", data),
        this.cancelSkillEditing
      );
    },

    checkValidation() {
      if (!this.skills.length) return true;

      const draggable = this.$children.find((componentForm) => {
        return componentForm.$options.name === "draggable";
      });

      const skillsForms = draggable.$children[0].$children.filter(
        (componentForm) => {
          return (
            componentForm.$options.name === LOCAL_COMPONENTS_NAMES.SKILL_FORM
          );
        }
      );

      let isValidForms = true;
      skillsForms.forEach((skillsForm) => {
        if (!skillsForm.checkValidationForm()) {
          isValidForms = false;
        }
      });

      return isValidForms;
    },
  },

  async mounted() {
    let back_btn = CookieHelper.getFlag("back_btn", "");
    if (back_btn == "1") {
      this.isShowBackButton = true;
    }
  },

  async created() {
    let vm = this;
    this.mixpanelTrack("V2-SKILLS");
    await this.$store.dispatch("loader/pending");
    await this.getInitialResume(this.resumeHash());
    this.setInitialExperience(this.initialSkills, "skills");

    await this.$store.dispatch("loader/done");
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/modules/work-education-skill-steps.scss";
@import "../../../assets/scss/modules/form.scss";

.creation-form-header__preview.order-md-2 {
  position: absolute;
  top: 0 !important;
  right: 9px;
  background: #fff;
  width: 70px;
  margin: 12px;
  padding: 2px;
  display: block;
  min-width: auto;
  z-index: 22;
  border: 2px solid #4874e6;
  border-radius: 30px;
}

button.creation-form-header__preview.order-md-2 svg {
  width: 17px;
  fill: #4874e6;
}
</style>
