<template>
  <div
    class="skill-levels"
    :class="{
      'skill-levels--error': isInvalid,
    }"
  >
    <div
      v-for="(level, index) of levels"
      :key="index"
      class="level"
      :class="{ 'level--selected': level.number === selectedLevelNumber }"
      @click.prevent="selectLevel(level)"
    >
      <div class="level__number">
        {{ level.number }}
      </div>

      <p class="level__label">
        {{ level.name }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "SkillLevels",

  props: {
    levels: {
      type: Array,
    },

    value: {
      type: [String, Number],
      default: null,
    },

    isInvalid: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedLevelNumber: null,
    };
  },

  methods: {
    selectLevel(level) {
      this.$emit("select", level.number);
      this.selectedLevelNumber = level.number;
    },
  },

  created() {
    this.selectedLevelNumber = Number(this.value);
  },
};
</script>

<style lang="scss" scoped>
.skill-levels {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  //@media (min-width: map_get($grid-breakpoints, sm)) {
  //  width: auto;
  //}

  @media (min-width: map_get($grid-breakpoints, md)) {
    width: auto;
    margin: 0 -8px;
  }

  @media (min-width: map_get($grid-breakpoints, lg)) {
    width: 100%;
    margin: 0;
  }

  @media (min-width: map_get($grid-breakpoints, xl)) {
    width: auto;
    margin: 0 -8px;
  }
}

.level {
  position: relative;
  margin: 0 4px;
  cursor: pointer;

  @media (min-width: map_get($grid-breakpoints, md)) {
    margin: 0 8px;
  }

  &__number {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 37px;
    height: 37px;
    border: 1px solid #d5d5d5;
    border-radius: 50%;
    font-weight: 600;
    font-size: 14px;
    color: $light-gray;
    transition: all 0.3s ease;
    line-height: 1;

    @media (min-width: map_get($grid-breakpoints, sm)) {
      width: 40px;
      height: 40px;
      font-size: 16px;
    }
  }

  &__label {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 8px;
    font-size: 12px;
    line-height: 24px;
    color: $light-gray;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover {
    .level__number {
      border-color: $primary;
      background-color: lighten($primary, 35%);
      color: $primary;
    }
  }

  &--selected {
    .level__number {
      border-color: $primary;
      background-color: $primary;
      color: #fff;
    }

    .level__label {
      opacity: 1;
    }

    &:hover {
      .level__number {
        border-color: $primary;
        background-color: $primary;
        color: #fff;
      }
    }
  }
}

.skill-levels--error {
  .level {
    .level__number {
      border-color: $red;
      color: $red;
    }
  }
}
</style>
