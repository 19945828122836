<template>
  <form
    class="experience-form skill-form"
    :class="{
      'experience-form--active': isActive,
    }"
    :key="refreshForm"
    @submit.prevent="submitForm"
  >
    <transition name="fade">
      <span
        v-if="activeId !== null && !isActive"
        class="experience-form-mask"
      ></span>
    </transition>

    <div class="skill-form-header">
      <h3 class="skill-form-header__title">Adding new skill</h3>
      <p class="skill-form-header__text">
        Enter the name of the skill and select the level of proficiency
      </p>
    </div>

    <div class="skill">
      <AppFieldSelect
        label="Skill name"
        class="skill__field first-focus"
        :value="experience.name"
        :getOptionHandler="getResumeSkills"
        :isInvalid="$v.experienceData.name.$error"
        @input="input($event)"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
        required
      >
        <template v-slot:error>
          <p v-if="!$v.experienceData.name.required">This field is required</p>
        </template>
      </AppFieldSelect>

      <SkillLevels
        class="skill__level"
        :levels="levels"
        :value="Number(experience.level)"
        :isInvalid="$v.experienceData.level.$error"
        @select="setField($event, 'level')"
      />
    </div>

    <div v-show="isEditing" class="experience-form-btns">
      <AppButton
        type="button"
        @click.prevent="submit"
        title="Save"
        class="
          experience-form-btns__item
          skill-form-btns__item
          experience-form-btns__item--submit
        "
        className="primary"
      />

      <button
        type="button"
        class="
          experience-form-btns__item experience-form-btns__item--cancel
          skill-form-btns__item
        "
        @click.prevent="cancel"
      >
        Cancel
      </button>
    </div>

    <div class="preview">
      <div class="preview-heading">
        <h3 class="preview-heading__title">
          {{ experience.name }}
          <span>( {{ selectedNameLevel }} )</span>
        </h3>
      </div>

      <div class="preview-controls-wrap" v-click-outside="closeMobileControls">
        <button
          class="mobile-control-btn"
          :class="{ 'mobile-control-btn--opened': isOpenMobileControls }"
          @click.stop.prevent="toggleMobileControls"
        >
          <AppIcon
            componentName="DotsVerticalIcon"
            class="control-btn-icon control-btn-icon--default"
          />
          <AppIcon
            componentName="CloseIcon"
            class="control-btn-icon control-btn-icon--close"
          />
        </button>

        <transition name="fade">
          <div
            v-if="isMobileDisplay ? isOpenMobileControls : true"
            class="preview-controls"
          >
            <button
              type="button"
              class="preview-controls__btn preview-controls__btn--move"
            >
              <AppIcon componentName="MoveIcon" />
            </button>

            <span class="preview-controls__separator"></span>

            <button
              type="button"
              class="preview-controls__btn"
              @click.prevent="onClickEditForm"
            >
              <AppIcon componentName="EditIcon" />
            </button>

            <span class="preview-controls__separator"></span>

            <button
              class="preview-controls__btn"
              @click.prevent="removeExperience"
            >
              <AppIcon componentName="TrashIcon" />
            </button>
          </div>
        </transition>
      </div>
    </div>
  </form>
</template>

<script>
import $ from "jquery";

import { mapActions } from "vuex";
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";

import { LOCAL_COMPONENTS_NAMES } from "@/helpers/components-names";
import { experienceFormMixin } from "@/mixins/ExperienceFormMixin";
import SkillLevels from "@/components/creation/SkillLevels";
import AppFieldSelect from "@/components/ui/AppFieldSelect";

export default {
  name: LOCAL_COMPONENTS_NAMES.SKILL_FORM,
  mixins: [experienceFormMixin],
  components: { AppFieldSelect, SkillLevels },

  props: {
    experience: {
      type: Object,
      required: true,
    },

    experienceList: {
      type: Object,
    },

    activeId: {
      type: [Number, String],
    },

    isMobileDisplay: {
      type: Boolean,
      default: false,
    },

    isEditing: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      initialWorkHistories: "resumeCreation/getWorkHistory",
    }),
    selectedNameLevel() {
      const selectedLevel = this.levels.find((level) => {
        return level.number === Number(this.experience.level);
      });

      return selectedLevel?.name || "Unrecognized experience";
    },
  },

  validations() {
    return {
      experienceData: {
        name: {
          required,
        },
        level: {
          required,
        },
      },
    };
  },

  data() {
    return {
      levels: [
        {
          number: 1,
          name: "Novice",
        },
        {
          number: 2,
          name: "Beginner",
        },
        {
          number: 3,
          name: "Skillful",
        },
        {
          number: 4,
          name: "Experienced",
        },
        {
          number: 5,
          name: "Expert",
        },
      ],
      skill: "",
    };
  },

  methods: {
    async getResumeSkills(q) {
      let payload = {
        querySearch: q,
        jobRole:
          this.initialWorkHistories[0] != undefined
            ? this.initialWorkHistories[0].job_title
            : "Generic",
      };
      let result = await this.$store.dispatch(
        "resumeCreation/getResumeSkills",
        payload
      );

      let result_filtered = [];

      for (let i = 0; i < result.length; i++) {
        if (
          result[i].content != null &&
          !this.checkExistInCurrentSelection(result[i].content)
        ) {
          result_filtered.push(result[i]);
        }
      }

      return result_filtered;
    },
    checkExistInCurrentSelection(content) {
      if (content == null) {
        return false;
      }

      for (let i = 0; i < this.experienceList.length; i++) {
        if (
          this.experienceList[i].name != null &&
          this.experienceList[i].name.indexOf(content) > -1
        ) {
          return true;
        }
      }
      return false;
    },
    onClickEditForm() {
      localStorage.setItem("skill", JSON.stringify(this.experience));
      this.skill = this.experience.name;
      this.editForm();
    },
    input(value) {
      this.skill = value;
    },
    submit() {
      this.setField(this.skill, "name");
      this.submitForm();
    },
    cancel() {
      let data = localStorage.getItem("skill");
      if (data) {
        data = JSON.parse(data);
        this.setField(data.name, "name");
        this.setField(data.level, "level");
        localStorage.removeItem("skill");
      }
      this.cancelEditing();
    },
  },

  mounted() {
    // if ($(".first-focus input:text").last().val() == "") {
    //   $(".first-focus input:text").last().focus();
    // }
  },

  created() {
    this.skill = this.experience.name;
  },
};
</script>

<style lang="scss" scoped>
.skill-form-header {
  margin-bottom: 24px;

  &__title {
    font-weight: 600;
    font-size: 16px;
  }

  &__text {
    font-size: 14px;
    color: $light-gray;
    margin-top: 8px;
  }
}

.skill {
  //width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -20px;

  @media (min-width: map_get($grid-breakpoints, md)) {
    flex-wrap: nowrap;
  }

  @media (min-width: map_get($grid-breakpoints, lg)) {
    flex-wrap: wrap;
  }

  @media (min-width: map_get($grid-breakpoints, xl)) {
    flex-wrap: nowrap;
  }

  &__field {
    width: 100%;
    margin: 0 20px 24px;

    @media (min-width: map_get($grid-breakpoints, md)) {
      margin-bottom: 0;
    }

    @media (min-width: map_get($grid-breakpoints, lg)) {
      margin-bottom: 24px;
    }

    @media (min-width: map_get($grid-breakpoints, xl)) {
      margin-bottom: 0;
      max-width: 360px;
    }
  }

  &__level {
    margin: 0 20px 40px;

    @media (min-width: map_get($grid-breakpoints, md)) {
      margin-bottom: 0;
    }

    @media (min-width: map_get($grid-breakpoints, lg)) {
      margin-bottom: 20px;
    }

    @media (min-width: map_get($grid-breakpoints, xl)) {
      margin-bottom: 0;
    }
  }
}

.skill-form-btns__item:last-child {
  @media (min-width: map_get($grid-breakpoints, lg)) {
    margin-left: 0 !important;
    padding: 10px 32px;
  }
}

.preview-heading__title.preview-heading__title {
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .preview-heading__title,
  .preview-heading__title span {
    font-size: 21px !important;
  }
}
</style>
